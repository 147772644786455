<template>
    <div :class="['w-full flex', toolTip ? 'flex-row' : 'flex-col', $attrs.class]">
        <CoreInput
            v-bind="$attrs"
            :name="$attrs.name || $attrs.id || $attrs.label || $attrs.placeholder"
            :label-wrapper-classes="(display==='inline' ? 'sm:col-span-'+labelSize+'':'') + ' flex items-start justify-between' + ($attrs.type !== 'hidden' ? ' mb-1' : '')"
            label-classes="block text-gray-900 dark:text-gray-300 text-sm font-medium leading-6"
            :input-wrapper-classes="(display==='inline' ? 'mt-2 sm:col-span-'+inputSize+' sm:mt-0':'') + ' relative'"
            :input-classes="`${defaultInputClasses} ${inputClasses}`"
            input-invalid-classes="ring-red-600 ring-2"
            :error-classes="'mt-1 text-sm text-red-600' + (display==='inline' ? ' sm:col-start-'+(parseInt(labelSize)+1)+' sm:col-span-'+inputSize:'')"
            :class="display==='inline' ? 'sm:grid sm:grid-cols-6 sm:items-stretch sm:gap-x-4 sm:py-1':'w-full'">
            <template
                v-for="(_, slot) in $slots"
                #[slot]="scope">
                <slot
                    :name="slot"
                    v-bind="{...scope}" />
            </template>
            <template
                v-if="$slots.inputHelper"
                #inputHelper>
                <slot name="inputHelper" />
            </template>
            <template
                v-if="$slots.inputIcon"
                #inputIcon>
                <slot name="inputIcon" />
            </template>
        </CoreInput>
        <div
            v-if="toolTip"
            class="pl-1 flex-shrink">
            <BasePopover
                size="sm"
                :position="toolTip === true ? 'left' : toolTip">
                <template #trigger>
                    <div class="flex items-center h-9 mt-7">
                        <Icon
                            v-if="toolTip"
                            name="heroicons:question-mark-circle"
                            class="h-5 w-5 text-primary-500 hover:bg-primary-500 hover:text-white hover:rounded-full" />
                    </div>
                </template>
                <template #default>
                    <span class="text-neutral-500 dark:text-gray-300 mt-2">
                        {{ description }}
                    </span>
                </template>
            </BasePopover>
        </div>
        <div
            v-if="!toolTip && description"
            class="text-sm mt-1">
            <span class="text-neutral-500 dark:text-gray-300">
                {{ description }}
            </span>
        </div>
    </div>
</template>

<script setup>
defineOptions({
    inheritAttrs: false
})

const defaultInputClasses = `block w-full rounded-md py-1.5 border-0 ring-1 ring-inset ring-gray-300 text-gray-900 shadow-sm placeholder:text-gray-400
                             appearance-none rounded w-full py-2 px-3 leading-tight
                             focus:ring-primary focus:ring-2 focus:ring-inset sm:text-sm focus:outline-none focus:shadow-outline
                             disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 disabled:shadow-none disabled:cursor-not-allowed
                             dark:text-gray-200 dark:placeholder:text-gray-500 disabled:dark:bg-gray-800 disabled:dark:text-gray-300 disabled:dark:ring-gray-400 disabled:dark:text-gray-400`

// Variable must NOT be deleted! Otherwise, the tailwind classes will not be available in the final render due to dynamically generated classes
const availableGridClassesForTailwindRender = `sm:col-start-1 sm:col-start-2 sm:col-start-3 sm:col-start-4 sm:col-start-5 sm:col-start-6
                                               sm:col-span-1 sm:col-span-2 sm:col-span-3 sm:col-span-4 sm:col-span-5 sm:col-span-6`

const props = defineProps({
    description: {
        type: String,
        default: null
    },
    toolTip: {
        type: Boolean || String,
        default: false,
        description: 'true == left || right || center'
    },
    display: {
        type: String,
        default: 'block'
    },
    labelSize: {
        type: String,
        default: '2'
    },
    inputSize: {
        type: String,
        default: '4'
    },
    inputClasses: {
        type: String,
        default: ''
    }
})
</script>